import logger from './logger';

const AUTH_REFACTOR_FEATURE_FLAG = 'auth-refactor-feature-flag';

export const setAuthRefactorFeatureFlag = (flag: string) => {
  logger.info(`Setting auth refactor feature flag to ${flag}`);
  sessionStorage.setItem(AUTH_REFACTOR_FEATURE_FLAG, flag);
};

export const getAuthRefactorFeatureFlag = (): boolean => {
  const authRefactorFeatureFlag = sessionStorage.getItem(
    AUTH_REFACTOR_FEATURE_FLAG
  );

  return authRefactorFeatureFlag === 'true' ? true : false;
};
