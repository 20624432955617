import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AGENT_INTERCEPTION_CONFIRM_CONTENT } from '../../constants';
import { AIStatusColors } from '../../redux/features/ai/ai.constants';
import {
  selectAIStatus,
  selectIsAIAutoMode,
  selectIsAIEscalation,
} from '../../redux/features/ai/ai.selector';
import Colors from '../../utils/color';
import ConfirmDialog from '../common/ConfirmDialog';

interface IAIStatusBarStyleProps {
  isAIAutoMode: boolean;
  isAIEscalation: boolean;
  aiStatusColor: AIStatusColors;
}

interface IAgentInterceptionConfirmDialog {
  open: boolean;
  confirm: boolean;
}

const getStatusBarColor = (aiStatusColor: AIStatusColors) => {
  switch (aiStatusColor) {
    case AIStatusColors.green:
      return Colors.emerald;
    case AIStatusColors.yellow:
      return Colors.gold;
    case AIStatusColors.red:
      return Colors.bittersweet;
    default:
      return Colors.emerald;
  }
};

const useStyles = makeStyles<Theme, IAIStatusBarStyleProps>(({ spacing }) => ({
  aiStatusBarClass: {
    width: '100%',
    height: '48px',
    backgroundColor: ({ isAIEscalation, aiStatusColor }) =>
      isAIEscalation ? Colors.red : getStatusBarColor(aiStatusColor),
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1),
    overflow: `auto hidden`,
  },
  emptyBarClass: {
    paddingLeft: spacing(3),
    fontSize: '0.75rem',
  },
  boldFontClass: {
    fontWeight: 'bold',
  },
}));

const AIStatusBar: FC = (props) => {
  const isAIAutoMode = useSelector(selectIsAIAutoMode);
  const isAIEscalation = useSelector(selectIsAIEscalation);
  const aiStatus = useSelector(selectAIStatus);
  const { aiStatusBarClass, emptyBarClass, boldFontClass } = useStyles({
    isAIAutoMode,
    isAIEscalation,
    aiStatusColor: aiStatus.status,
  });
  const confirmAgentInterception =
    isAIAutoMode && aiStatus.status === AIStatusColors.green && !isAIEscalation;
  const [agentInterceptionConfirmDialog, setAgentInterceptionConfirmDialog] =
    useState<IAgentInterceptionConfirmDialog>({ open: false, confirm: false });

  useEffect(() => {
    if (confirmAgentInterception && !agentInterceptionConfirmDialog.confirm) {
      const handleKeyboardMouseEvent = () => {
        setAgentInterceptionConfirmDialog((prevState) => ({
          ...prevState,
          open: true,
        }));
      };
      document.addEventListener('keydown', handleKeyboardMouseEvent, true);
      document.addEventListener('mousedown', handleKeyboardMouseEvent, true);
      return () => {
        document.removeEventListener('keydown', handleKeyboardMouseEvent, true);
        document.removeEventListener(
          'mousedown',
          handleKeyboardMouseEvent,
          true
        );
      };
    }
  }, [confirmAgentInterception, agentInterceptionConfirmDialog]);

  return (
    <div data-testid="ai-status-bar" className={aiStatusBarClass}>
      <div className={emptyBarClass}>
        <span className={boldFontClass}>AUTO AI: </span>
        {isAIEscalation
          ? 'ALERT, Agent take over session now. AI cannot continue with order.'
          : aiStatus.message}
      </div>
      <ConfirmDialog
        open={agentInterceptionConfirmDialog.open}
        content={AGENT_INTERCEPTION_CONFIRM_CONTENT}
        onClose={() =>
          setAgentInterceptionConfirmDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        onConfirm={() =>
          setAgentInterceptionConfirmDialog({ open: false, confirm: true })
        }
        closeBtnText={'No'}
        confirmBtnText={'Yes'}
      />
    </div>
  );
};

export default AIStatusBar;
