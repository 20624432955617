import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { EventTypes } from '../../../constants/event';
import { selectMenuVersion } from '../../../reducers/menuSlice';
import {
  IAIStatusTransmissionMessage,
  IMessageReceived,
  messagingActions,
} from '../../../reducers/messagingSlice';
import { selectStage } from '../../../reducers/restaurantSlice';
import logger from '../../../utils/logger';
import {
  AIStatusColors,
  DEFAULT_STATUS_MESSAGE,
  SLICE_NAME,
} from './ai.constants';
import { initialState } from './ai.utils';

export const aiSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetAI: () => {
      return initialState;
    },
    setIsAIAutoMode: (state, { payload }) => {
      state.isAIAutoMode = payload;
    },
    setIsAIEscalation: (state, { payload }) => {
      state.isAIEscalation = payload;
    },
    toggleReasonsDialog: (state, { payload }) => {
      state.interceptionReasonsDialogOpen = payload;
    },
    setAIStatus: (state, { payload }) => {
      state.aiStatus = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<IMessageReceived>) => {
        const { messages, taskRouter, sessionBoundarySessionId } =
          action.payload;
        messages.forEach((message) => {
          if (
            (taskRouter?.currentTaskId &&
              taskRouter?.taskAssignedSessionId !== message.session_id) ||
            (sessionBoundarySessionId &&
              sessionBoundarySessionId !== message.session_id)
          ) {
            //If the current task is from task-router and if the message session is not matching with
            //task-router current task session or the message session is not matching with the current session id in sessionBoundary , don't process the received message
            return;
          }

          if (message.event === EventTypes.aiEscalation) {
            logger.log('ai_escalation received');
            state.isAIEscalation = true;
          } else if (
            message.event === EventTypes.aiStatus &&
            !(state.aiStatus?.status === AIStatusColors.red) &&
            !state.isAIEscalation
          ) {
            state.aiStatus =
              (message as IAIStatusTransmissionMessage).data || {};
          }
        });
      }
    );
    builder.addCase(messagingActions.sendAgentInterception, (state) => {
      if (state.isAIAutoMode) state.isAIAutoMode = false;
    });
    builder.addMatcher(
      isAnyOf(selectStage.fulfilled, selectMenuVersion.fulfilled),
      (state, action) => {
        state.isAIAutoMode = true;
        state.isAIEscalation = false;
        state.aiStatus = {
          status: AIStatusColors.green,
          message: DEFAULT_STATUS_MESSAGE,
        };
        state.interceptionReasonsDialogOpen = false;
      }
    );
  },
});

const {
  resetAI,
  toggleReasonsDialog,
  setIsAIAutoMode,
  setIsAIEscalation,
  setAIStatus,
} = aiSlice.actions;

export {
  resetAI,
  setAIStatus,
  setIsAIAutoMode,
  setIsAIEscalation,
  toggleReasonsDialog,
};
export default aiSlice.reducer;
